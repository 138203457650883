<template>
    <div class="result">
       暂未开发
    </div>
</template>

<script>
    export default {
        name: "form-info"
    }
</script>

<style scoped>
 .result{
     height: 300px;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     color: rgba(0, 0, 0, 0.07);
     font-size: 40px;
 }
</style>
