<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<span>选择图标</span>
			</div>
		</div>
		<div style="margin-bottom: 10px;">
			<a-input-search
				name="filterText"
				:placeholder="l('SearchWithThreeDot')"
				@search="search"
				auto-focus
				enterButton
				v-model="filterText"
			/>
		</div>
		<div class="icon-data" ref="iconData">
			<div
				class="icon-data-row border-off"
				v-for="(item, key) in showList"
				@click="checkIcon(iconFont.css_prefix_text + item.font_class)"
				:class="{
					'border-off': hasIcon(iconFont.css_prefix_text + item.font_class),
					'border-on': hasIcon(iconFont.css_prefix_text + item.font_class),
				}"
				:title="item.name"
				:key="key"
			>
				<div>
					<icon-font :type="iconFont.css_prefix_text + item.font_class" style="font-size: 38px"></icon-font>
				</div>
				<div style="font-size: 12px;">{{ item.name }}</div>
			</div>
		</div>
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../shared/component-base/modal-component-base';
import Icons from './icons';
import { AppConsts } from '@/abpPro/AppConsts';
import { TokenAuthServiceProxy } from '../../shared/service-proxies';
import { apiHttpClient } from '../../shared/utils';

export default {
	name: 'icon-model',
	mixins: [ModalComponentBase],
	data() {
		return {
			iocnData: Icons.codes,
			code: undefined,
			iconFont: {},
			orgList: [],
			showList: [],
			type: 1,
			filterText: '',
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._tokenAuthService = new TokenAuthServiceProxy(AppConsts.remoteServiceBaseUrl, apiHttpClient);
	},
	mounted() {
		this._tokenAuthService.getIconFont().then((res) => {
			this.iconFont = res;
			this.showList = this.orgList = this.iconFont.glyphs;
		});
		this.$nextTick(() => {
			this.$refs.iconData.style.maxHeight = (window.innerHeight / 5) * 3 + 'px';
		});
	},
	methods: {
		hasIcon(item) {
			if (item == this.code) {
				return true;
			} else {
				return false;
			}
		},
		search() {
			this.showList = this.orgList.filter((o) => {
				return o.font_class.indexOf(this.filterText) != -1 || o.name.indexOf(this.filterText) != -1;
			});
		},
		checkIcon(item) {
			this.code = item;
		},
		save() {
			this.success(this.code);
		},
	},
};
</script>

<style scoped>
@import './iocn-model.less';
</style>
