<template>
    <a-spin :spinning="false">
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="index != undefined">{{ l("修改按钮") }}</span>
                <span v-else>{{ l("添加按钮") }}</span>
            </div>
        </div>
        <div class="my-md">
            <a-row>
                <a-col :span="5"> 名称: </a-col>
                <a-col :span="19">
                    <a-input
                        :placeholder="l('Name')"
                        v-model="entity.fullName"
                    />
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="5"> 编号: </a-col>
                <a-col :span="19">
                    <a-input
                        :placeholder="l('Code')"
                        v-model="entity.enCode"
                        @keypress.enter="btnSave"
                    />
                </a-col>
            </a-row>

            <!--            <a-row>-->
            <!--                <a-col :span="5">-->
            <!--                    上级按钮-->
            <!--                </a-col>-->
            <!--                <a-col :span="19">-->
            <!--                    <a-select style="width: 100%" v-model="entity.parentId" >-->
            <!--                        <a-select-option value="iframe">-->
            <!--                            iframe-->
            <!--                        </a-select-option>-->
            <!--                    </a-select>-->
            <!--                </a-col>-->
            <!--            </a-row>-->
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l("Cancel") }}
            </a-button>
            <a-button :type="'primary'" @click="btnSave()">
                <a-icon type="save" />
                {{ l("Save") }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModuleButtonEditDto } from "@/shared/service-proxies";

export default {
    name: "create-or-edit-btn-info",
    mixins: [ModalComponentBase],
    data() {
        return {
            entity: new ModuleButtonEditDto(),
            index: undefined,
            btnList: [],
        };
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
    },
    methods: {
        btnSave() {
            if (!this.entity.fullName) {
                return abp.message.warn("名称不能为空");
            }
            if (!this.entity.enCode) {
                return abp.message.warn("编码不能为空");
            }
            let item = false;
            //判断是为为修改状态
            if (this.index !== undefined) {
                for (let i = 0; i < this.btnList.length; i++) {
                    if (i === this.index) {
                        continue;
                    }
                    if (this.btnList[i].enCode === this.entity.enCode) {
                        item = true;
                        break;
                    }
                }
            }
            if (item) {
                return abp.message.warn("编码重复");
            }
            this.entity.parentId = 0;
            this.success({
                success: true,
                entity: this.entity,
                index: this.index,
            });
        },
    },
};
</script>

<style scoped></style>
