export  class  Icons {
    static codes=[
        "edit",
        "copy",
        "delete",
        "smile",
        "sound",
        "tag",
        "alert",
        "credit-card",
        "heart",
        "user",
        "message",
        "bell",
        "gift",
        "mail",
        "setting",
        "idcard",
        "environment",
        "frown",
        "phone",
        "notification"
    ];
}
export  default Icons;
