<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <a-icon type="medicine-box" />
                    <span v-if="index != undefined">{{ l("修改列") }}</span>
                    <span v-else>{{ l("添加列") }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row>
                    <a-col :span="5"> 表格名称: </a-col>
                    <a-col :span="19">
                        <a-input
                            placeholder="表格名称"
                            v-model="entity.gridName"
                        />
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="5"> 名称: </a-col>
                    <a-col :span="19">
                        <a-input
                            :placeholder="l('Name')"
                            v-model="entity.fullName"
                        />
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="5"> 编号: </a-col>
                    <a-col :span="19">
                        <a-input
                            :placeholder="l('Code')"
                            v-model="entity.enCode"
                        />
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="5"> 排序号: </a-col>
                    <a-col :span="19">
                        <a-input
                            :placeholder="l('排序号')"
                            v-model="entity.sortCode"
                        />
                    </a-col>
                </a-row>
            </div>
            <!-- 按钮 -->
            <div class="modal-footer">
                <a-button @click="close()" type="button">
                    <a-icon type="close-circle" />
                    {{ l("Cancel") }}
                </a-button>
                <a-button :type="'primary'" @click="save()">
                    <a-icon type="save" />
                    {{ l("Save") }}
                </a-button>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { ModuleColumnEditDto } from "@/shared/service-proxies";

export default {
    name: "create-or-edit-column-info",
    mixins: [ModalComponentBase],
    data() {
        return {
            zh_CN,
            index: undefined,
            entity: new ModuleColumnEditDto(),
            dataList: [],
        };
    },
    created() {
        this.fullData();
    },
    mounted() {},
    methods: {
        save() {
            if (!this.entity.gridName) {
                return abp.message.warn("请输入表格名称");
            }
            if (!this.entity.fullName) {
                return abp.message.warn("请输入名称");
            }
            if (!this.entity.enCode) {
                return abp.message.warn("请输入编码");
            }

            var item = [];
            if (this.index == undefined) {
                item = this.dataList.filter(
                    (item, index) =>
                        item.enCode == this.entity.enCode &&
                        item.gridName == this.entity.gridName
                );
            } else {
                item = this.dataList.filter(
                    (item, index) =>
                        item.enCode == this.entity.enCode &&
                        item.gridName == this.entity.gridName &&
                        index != this.index
                );
            }
            if (item.length > 0) {
                return abp.message.warn("编码重复");
            }
            this.success({
                index: this.index,
                entity: this.entity,
                success: true,
            });
        },
    },
};
</script>

<style scoped></style>
