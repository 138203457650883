<template>
    <a-config-provider :locale="zh_CN">
        <div class="my-md">
            <a-row :gutter="8">
                <a-col :span="6">
                    <a-spin :spinning="treeLoading">
                        <a-card>
                            <a-row :gutter="8">
                                <a-col :span="16">
                                    <p class="left">
                                        <a-icon type="share-alt" />
                                        {{ l("模块") }}
                                    </p>
                                </a-col>
                                <a-col :span="8">
                                    <a @click="onreload">
                                        <p class="left" style="color: #49a9ee">
                                            <a-icon type="reload" />
                                            {{ l("刷新") }}
                                        </p>
                                    </a>
                                </a-col>
                            </a-row>
                            <a-tree
                                class="draggable-tree"
                                :tree-data="TreeData"
                                @select="selectTree"
                            >
                            </a-tree>
                        </a-card>
                    </a-spin>
                </a-col>

                <a-col :span="18">
                    <a-spin :spinning="tableLoading">
                        <a-card>
                            <!--                    操作按钮-->
                            <div class="my-md">
                                <a-row :gutter="8">
                                    <a-col :span="11">
                                        <a-button
                                            :type="'primary'"
                                            @click="Create"
                                        >
                                            <a-icon type="plus" />
                                            <span>{{ l("Create") }}</span>
                                        </a-button>
                                        <a-button
                                            :type="'primary'"
                                            @click="Edit"
                                        >
                                            <a-icon type="edit" />
                                            <span>{{ l("Edit") }}</span>
                                        </a-button>
                                        <a-button
                                            :type="'danger'"
                                            @click="Delete"
                                        >
                                            <a-icon type="delete" />
                                            <span>{{ l("Delete") }}</span>
                                        </a-button>
                                        <a-button
                                            :type="'primary'"
                                            @click="Sync"
                                        >
                                            <a-icon type="primary" />
                                            <span>{{
                                                l("全部菜单复制到租户")
                                            }}</span>
                                        </a-button>
                                    </a-col>
                                    <a-col :span="12" :offset="1">
                                        <a-form
                                            :layout="'vertical'"
                                            @submit.prevent="getData"
                                        >
                                            <a-row :gutter="8">
                                                <!-- 搜索 -->
                                                <a-col :sm="24">
                                                    <a-form-item>
                                                        <a-input-search
                                                            name="filterText"
                                                            :placeholder="
                                                                l(
                                                                    'SearchWithThreeDot'
                                                                )
                                                            "
                                                            @search="getData"
                                                            enterButton
                                                            v-model="filterText"
                                                            v-decorator="[
                                                                'filterText',
                                                            ]"
                                                        />
                                                    </a-form-item>
                                                </a-col>
                                            </a-row>
                                        </a-form>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="my-md">
                                <a-table
                                    class="list-table"
                                    :pagination="false"
                                    :columns="columns"
                                    :customRow="customRow"
                                    :rowKey="(tableDatas) => tableDatas.id"
                                    :dataSource="tableData"
                                >
                                    <a-checkbox
                                        slot="checkbox"
                                        slot-scope="text, record"
                                        v-model="record.checked"
                                    >
                                    </a-checkbox>
                                    <span
                                        slot="isMenu"
                                        slot-scope="text, record"
                                    >
                                        <a-switch
                                            size="small"
                                            :default-checked="
                                                record.isMenu ? true : false
                                            "
                                            disabled
                                        />
                                    </span>
                                    <span
                                        slot="allowExpand"
                                        slot-scope="text, record"
                                    >
                                        <a-switch
                                            size="small"
                                            :default-checked="
                                                record.allowExpand
                                                    ? true
                                                    : false
                                            "
                                            disabled
                                        />
                                    </span>
                                    <span
                                        slot="enabledMark"
                                        slot-scope="text, record"
                                    >
                                        <a-switch
                                            size="small"
                                            :default-checked="
                                                record.enabledMark
                                                    ? true
                                                    : false
                                            "
                                            disabled
                                        />
                                    </span>
                                    <template
                                        slot="Edit"
                                        slot-scope="text, record"
                                    >
                                        <a-button
                                            :type="'primary'"
                                            @click="Sync(record)"
                                        >
                                            <span>{{ l("复制菜单") }}</span>
                                        </a-button>
                                    </template>
                                </a-table>
                                <a-pagination
                                    class="pagination"
                                    :total="totalItems"
                                    v-model="pageNumber"
                                    showSizeChanger
                                    showQuickJumper
                                    :showTotal="showTotalFun"
                                    @change="onChange"
                                    @showSizeChange="showSizeChange"
                                />
                            </div>
                        </a-card>
                    </a-spin>
                </a-col>
            </a-row>
        </div>
    </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { ModalHelper } from "@/shared/helpers";
import CreateOrEditModule from "./create-or-edit-module/create-or-edit-module";
import { ModuleServiceProxy } from "@/shared/service-proxies";
import AppCompoentBase from "@/shared/component-base/app-component-base";
import synsadd from "../syns-add/syns-add.vue";

let _this;
export default {
    name: "host-module",
    mixins: [AppCompoentBase],
    components: { CreateOrEditModule, synsadd },
    data() {
        return {
            zh_CN,
            treeLoading: false,
            tableLoading: false,
            // 总数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            filterText: undefined,
            // 显示条数
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            request: { sorting: "", maxResultCount: 10, skipCount: 0 },
            TreeData: [],
            tableData: [],
            columns: [
                {
                    title: "",
                    dataIndex: "checkbox",
                    sorter: false,
                    align: "center",
                    width: 50,
                    scopedSlots: { customRender: "checkbox" },
                },
                {
                    title: this.l("ModuleCode"),
                    dataIndex: "enCode",
                    sorter: false,
                    ellipsis: true,
                    width: 150,
                    scopedSlots: { customRender: "enCode" },
                },
                {
                    title: this.l("ModuleName"),
                    dataIndex: "fullName",
                    sorter: false,
                    width: 80,
                    align: "center",
                    scopedSlots: { customRender: "fullName" },
                },
                {
                    title: this.l("ModuleAddr"),
                    dataIndex: "urlAddress",
                    sorter: false,
                    width: 100,
                    align: "center",
                    scopedSlots: { customRender: "urlAddress" },
                },
                {
                    title: this.l("OpenWay"),
                    dataIndex: "target",
                    width: 80,
                    align: "center",
                    scopedSlots: { customRender: "target" },
                },
                {
                    title: this.l("ModuleMenu"),
                    dataIndex: "isMenu",
                    align: "center",
                    width: 80,
                    scopedSlots: { customRender: "isMenu" },
                },
                {
                    title: this.l("ModuleOpen"),
                    dataIndex: "allowExpand",
                    align: "center",
                    width: 80,
                    scopedSlots: { customRender: "allowExpand" },
                },
                {
                    title: this.l("ModuleValid"),
                    dataIndex: "enabledMark",
                    align: "center",
                    width: 100,
                    scopedSlots: { customRender: "enabledMark" },
                },
                {
                    title: this.l("Edit"),
                    dataIndex: "Edit",
                    align: "center",
                    width: 80,
                    scopedSlots: { customRender: "Edit" },
                },
            ],
            moduleId: undefined,
            selectedRowKeys: [],
            selectRows: [],
        };
    },
    created() {
        this._moduleServiceProxy = new ModuleServiceProxy(
            this.$apiUrl,
            this.$api
        );
        _this = this;
    },
    mounted() {
        this.getData();

        this.getTreeData();
    },
    methods: {
        Sync(record) {
            if (!record.fullName) {
                record.fullName = "全部";
            }
            ModalHelper.create(
                synsadd,
                {
                    synsTenantMenu: record,
                },
                {
                    width: "600px",
                }
            ).subscribe((res) => {
                if (res) {
                    console.log("成功");
                }
            });
        },
        /**
         * 表格行点击
         * @param record 点击行的数据
         * @param index 点击行的下标
         */
        customRow: (record, index) => ({
            on: {
                click: () => {
                    record.checked = !record.checked;
                    if (record.checked) {
                        //判断是否存在
                        let u = _this.selectRows.find(
                            (item) => item.id == record.id
                        );
                        if (!u) {
                            _this.selectedRowKeys.push(record.id);
                            _this.selectRows.push(record);
                        }
                    } else {
                        _this.selectedRowKeys = [
                            ..._this.selectedRowKeys.filter(
                                (item) => item != record.id
                            ),
                        ];
                        _this.selectRows = [
                            ..._this.selectRows.filter(
                                (item) => item.id != record.id
                            ),
                        ];
                    }
                    _this.$forceUpdate();
                },
            },
        }),
        /**
         * 刷新
         */
        onreload() {
            this.moduleId = undefined;
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
            this.getTreeData();
            this.$forceUpdate();
        },
        /**
         * 获取数结构
         */
        getTreeData() {
            this.treeLoading = true;
            this._moduleServiceProxy
                .getModuleTree("1")
                .finally(() => {
                    this.treeLoading = false;
                })
                .then((res) => {
                    this.TreeData = res;
                });
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true;
            this.selectedRowKeys = [];
            this.selectRows = [];
            this._moduleServiceProxy
                .getPaged(
                    this.moduleId ? this.moduleId : 0,
                    undefined,
                    undefined,
                    "1",
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.tableLoading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.tableData.map((item) => {
                        item.checked = false;
                    });
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                "GridFooterDisplayText",
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.getData();
        },
        /**
         * 树选择
         */
        selectTree(key) {
            this.moduleId = key.length > 0 ? key[0] : 0;

            this.pageNumber = 1;
            this.request.skipCount = 0;

            this.getData();
        },
        /**
         * 添加模块
         * @constructor
         */
        Create() {
            ModalHelper.create(
                CreateOrEditModule,
                {
                    parentId: this.moduleId,
                },
                {
                    width: "1000px",
                }
            ).subscribe((res) => {
                if (res) {
                    this.getData();
                    this.getTreeData();
                }
            });
        },
        /**
         * 编辑模块
         * @constructor
         */
        Edit() {
            if (this.selectedRowKeys.length <= 0) {
                return abp.message.warn("至少选择一项");
            }
            if (this.selectedRowKeys.length > 1) {
                return abp.message.warn("只能选择一项");
            }
            ModalHelper.create(
                CreateOrEditModule,
                {
                    id: this.selectedRowKeys[0],
                },
                {
                    width: "1000px",
                }
            ).subscribe((res) => {
                if (res) {
                    this.getData();
                    this.getTreeData();
                }
            });
        },
        /**
         * 删除模块
         * @constructor
         */
        Delete() {
            if (this.selectedRowKeys.length <= 0) {
                return abp.message.warn("至少选择一项");
            }
            this.$confirm({
                title: "确认操作",
                cancelText: "取消",
                okText: "确认",
                content:
                    "你确认要删除这" + this.selectedRowKeys.length + "项吗？",
                onOk: () => {
                    this.loading = true;
                    let ids = _.map(this.selectedRowKeys);
                    this._moduleServiceProxy
                        .batchDelete(ids)
                        .finally(() => {
                            this.loading = false;
                        })
                        .then((res) => {
                            this.$notification["success"]({
                                message: this.l("SuccessfullyDeleted"),
                            });
                            this.pageNumber = 1;
                            this.request.skipCount = 0;
                            this.getData();
                            this.getTreeData();
                        });
                },
            });
        },
    },
};
</script>

<style scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
